
.eco-nfc-button {
  display: flex;

  flex-direction: column;
  align-items: center;
  justify-items: center;

  width: 100%;
  height: 8rem;
  padding: 1rem;

  img {
    width: 5rem;
    height: 5rem;
  }
}
