.eco-trip {
  height: 100%;
  display: flex;
  flex-direction: column;

  .eco-content {
    width: 100%;

    display: flex;
    flex-grow: 1;
    flex-direction: column;

    align-self: center;

    display: flex;
    text-align: center;
    align-items: stretch;
  }
}

.eco-buttons {
  bottom: 0;
  position: fixed;
  background: var(--ion-background-color);

  width: 100%;
  padding: 0.5rem;
  z-index: 999;
}