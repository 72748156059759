
.eco-login {
  height: 100%;
  display: flex;
  flex-direction: column;
}

.eco-login > .eco-logo {
  width: 80%;
  
  flex-grow: 1;
  align-self: center;
  
  display: flex;
  align-items: center;
}

.eco-login > .eco-inputs {
  flex-grow: .3;
}

.eco-login > .eco-button {
  margin: .5rem;
}
