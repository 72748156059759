
.version-check {
  display: flex;
  position: fixed;

  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  background: black;

  z-index: 9999;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
